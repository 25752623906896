<template>
  <div>
    <nav-bar></nav-bar>
    <service-support-nav current="-1"></service-support-nav>
    <div>
      <img src="../assets/images/service-support/support-banner.png" alt="" class="img">
    </div>
    <div class="container-1646">
      <div class="service-support-header">服务理念与宗旨</div>
      <div class="service-support-idea">
        <div>
          以用户为本，为用户提供连续、不间断的产品服务和保障服务是我们的一贯宗旨，运用标准、规范、快捷的服务体 系为用户提供全面的解决方案，无论您是在技术方面还是在使用方面出现问题，还是硬件出现故障，我们都将给予 全力支持和帮助，使您获得方便快捷的服务，快速地发挥产品与服务的优异性，我们将成为您的战略合作伙伴。
        </div>
        <br>
        <div>公司建立了完善的服务体系，组建了专业的技术服务团队，提供优质的服务帮助客户圆满解决所遇到的问题。公司的技术团队将根据客户的具体需要，多角色考虑设计系统，结合机房设计规范提供完整的解决方案。</div>
      </div>
      <div class="service-support-header">专业服务</div>
      <div class="flex-between">
        <div class="service-support-specialty" v-for="(item, index) in specialty" :key="index">
          <div class="service-support-specialty-title">
            {{ item.title }}
          </div>
          <div class="service-support-specialty-description">{{ item.description }}</div>
        </div>
      </div>
      <div class="service-support-header">热门服务</div>
      <div class="flex-between">
        <div class="service-support-hot" v-for="(item, index) in hot" :key="index">
          <div class="service-support-specialty-title">{{ item.title }}</div>
          <div class="service-support-specialty-description">{{ item.description }}</div>
          <div class="service-support-button pointer" @click="go(item.url)">了解详情 ></div>
        </div>
      </div>
      <div class="service-support-header">增值服务</div>
      <div class="service-support-add">多种保障服务，全面安心守护</div>
      <div class="service-support-button pointer" @click="go('/ValueAdded')">了解详情 ></div>
      <img src="../assets/images/service-support/addvalue.png" class="img addvalue" />
      <div class="service-support-header">联系我们</div>
      <div class="flex-between">
        <div class="service-support-us-left">
          <img src="../assets/images/service-support/phone.png" class="service-support-us-phone">
          <div class="service-support-us-left-hotline">热线服务</div>
          <div class="service-support-us-left-phone">热线电话：<span class="green">010-81333426</span></div>
          <div class="service-support-us-left-time"> 周一至周五 9:00-12:00 13:00-18:00(北京时间） </div>
        </div>
        <div>
          <img src="../assets/images/service-support/supportus.png" class="supportus" />
        </div>
      </div>
      <div class="remark">你也可以<span class="green">发送邮件</span>与我们联系。如有紧急需求，建议优先通过<span class="green">热线电话</span>获取更实时的服务。
      </div>
    </div>
    <div>
      <map-company></map-company>
    </div>
    <Footer></Footer>
  </div>
</template>
  
<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs, ref, nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import ServiceSupportNav from '@/components/ServiceSupportNav'
import MapCompany from '@/components/map'
import { useRouter } from 'vue-router';
export default {
  name: 'Home',
  components: {
    navBar,
    Footer,
    ServiceSupportBanner,
    ServiceSupportNav,
    MapCompany
  },

  setup() {
    const router = useRouter()
    const useData = reactive({
      specialty: [
        {
          title: '服务器租用/托管',
          description: '提供按需定制服务，性能稳定， 经济实用。',
        },
        {
          title: '机柜租用',
          description: '电信级标准机房，多线路可选，提供 标准机架及双路供电。',
        },
        {
          title: '大带宽',
          description: '与各大运营商互联互通。保证网络 接入需求，传输质量。',
        },
        {
          title: '运维外包',
          description: '经验丰富的系统工程师，7*24小时不间断为您提供服务支持。',
        }
      ],
      hot: [{
        title: '技术、状态服务',
        description: '技术服务、状态服务 ',
        url: 'technology'
      },
      {
        title: '常见问题',
        description: '针对您具体项目的需求,远程技术指导等整个过程的服务.',
        url: 'Questions'
      }, {
        title: '售后服务',
        description: '根据您选购的产品派遣技术工程师进行现场安装调试',
        url: 'AfterSupport'
      }]


    })
    const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
    }
    const go = (path) => {
      router.push({ path: path })
    }
    return {
      ...toRefs(useData),
      goTo,
      go,
    }
  },

}
</script>
<style scoped>
.service-support-header {
  font-size: 40px;
  padding: 140px 0px 80px;
  text-align: center;
}

.service-support-idea {
  background: #F5F5F5;
  padding: 77px 50px;
  font-size: 26px;
  line-height: 52px;
}

.service-support-specialty {
  width: 396px;
  height: 228px;
  background: #F5F5F5;
}

.service-support-specialty-title {
  font-size: 28px;
  padding: 44px 0px 20px;
  text-align: center;
}

.service-support-specialty-description {
  padding: 0px 60px 40px;
  color: #666666;
  font-size: 18px;
  text-align: center;
}

.service-support-hot {
  width: 539px;
  height: 256px;
  background: #F5F5F5;
}

.service-support-button {
  text-align: center;
  color: #53B578;
}

.addvalue {
  margin: 80px 0px 0px;
}

.service-support-us-left {
  width: 728px;
  height: 418px;
  background: #F5F5F5;
}

.service-support-us-left-hotline {
  font-size: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.service-support-us-left-phone {
  font-size: 24px;
  color: #666666;
  text-align: center;
  padding-bottom: 30px;
}

.service-support-us-left-time {
  color: #666666;
  text-align: center;
  font-size: 20px;
}

.service-support-us-phone {
  display: block;
  width: 66px;
  margin: 76px auto 30px;
}

.supportus {
  width: 921px;
  height: 418px;
}

.remark {
  text-align: center;
  padding: 30px 0px 60px;
  font-size: 20px;
}

.service-support-add {
  font-size: 30px;
  text-align: center;
  padding-bottom: 30px;
}</style>
  