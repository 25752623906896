<template>
   <div class="support-nav-content">
     <div class="container white flex-between">
            <div  class="support-nav-content-title">
              服务与支持
            </div>
            <div class="flex">
              <div style="" class="support-nav-content-list pointer"  v-for="(item,index) in supportNav" :key="index" :class="{active:index==current}" @click="go(item.url)">
                {{ item.name }}
              </div>
            </div>
          </div>
   </div>
</template>
<script>
import { reactive, onMounted, toRefs, ref, nextTick, computed } from 'vue'
import { useRouter } from 'vue-router';
export default {
  props: {
    current: {
      type: String,
      default:-1
    }
  },
  setup() {
    const router = useRouter()
        const useData = reactive({
          supportNav: [
          {
            name: '技术、状态服务',
            url:'technology'
          },
          {
            name: '常见问题',
            url:'Questions'
          },
          {
            name: '售后服务',
            url:'AfterSupport'
          },
          {
            name: '增值服务',
            url:'ValueAdded'
          }
        ],
        })
        const go = (path) => { 
      router.push({ path: path })
      }
        return {
          ...toRefs(useData),
          go
    }
        }
}
</script>
<style scoped>
.active{
  color: #999;
}
.support-nav-content{
  width:100% ;height: 60px;background: #000000;line-height: 60px;
  position: relative;
top: 90px;
left: 0;
}
.support-nav-content-title{
  font-size: 20px;
}
.support-nav-content-list{
  padding-left:60px ;
}
</style>